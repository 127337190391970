
<template>
	<div class="booking">
		<h2
			v-if="!viewIsSuccess()"
			class="booking__title"
		>
			Make a booking
		</h2>
		<template v-if="viewIsLoading()">
			Loading...
		</template>
		<template v-else-if="viewIsSuccess()">
			<div class="booking__confirmation">
				<p>Your booking has been confirmed, you’ll receive an email shortly with additional details.</p>
			</div>
			<div class="booking__message">
				<p>Please note: Latecomers will not be admitted. If you cannot make your booking please cancel at least 24 hours in advance. Members who repeatedly do not attend their bookings will have their membership suspended.</p>
			</div>
			<div>
				<a
					href="/workshop/tools"
					class="button button--large button--fullwidth button--invert button--center"
				>
					Back to all tools
				</a>
			</div>
		</template>
		<template v-else-if="viewIsConfirmBooking()">
			<div class="booking__detail">
				<dl class="booking__detail-row">
					<dt>Tool:</dt>
					<dd>{{ toolClassTitle }}</dd>
				</dl>
				<dl
					v-if="specific_instance_active"
					class="booking__detail-row"
				>
					<dt>Instance ID:</dt>
					<dd>{{ getToolInstanceIdFromId( booking_tool_instance ) }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Your name:</dt>
					<dd>{{ craft_user_data.fullName }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Location:</dt>
					<dd>{{ location_name }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Date:</dt>
					<dd>{{ booking_date }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Time:</dt>
					<dd>{{ getTimeslotRangeFromDate( booking_datetime ) }}</dd>
				</dl>
			</div>
			<div class="form__set booking__agreement">
				<div class="form__checkbox">
					<input
						id="booking-widget-member-agreement"
						v-model="agrees_to_agreement"
						type="checkbox"
					>
					<label for="booking-widget-member-agreement">
						I agree to the
						<a href="/members/member-agreement">
							Member Agreement
						</a>
						of Institute of Making
					</label>
				</div>
			</div>
			<button
				class="button button--large button--fullwidth button--invert button--center"
				:disabled="!booking_can_be_made"
				@click="bookTool"
			>
				Confirm booking
			</button>
		</template>
		<template v-else-if="viewIsError()">
			<div class="booking__message">
				<h3 class="booking__heading booking__heading--warning">
					Booking failed
				</h3>
				<p
					v-for="error, index in error_messages"
					:key="index"
				>
					{{ error }}
				</p>
			</div>
			<button
				class="button button--fullwidth button--invert button--center"
				@click="resetErrorState"
			>
				Go back
			</button>
		</template>
		<template v-else>
			<div class="form__set">
				<label
					for="booking-widget-location"
					class="form__label"
				>
					Select a location
				</label>
				<div class="form__icon form__icon--down">
					<select
						id="booking-widget-location"
						v-model="booking_location"
						class="form__select"
					>
						<option
							v-for="location in locations"
							:key="location.id"
							:value="location.id"
						>
							{{ location.title }}
						</option>
					</select>
				</div>
			</div>
			<template
				v-if="location_is_set"
			>
				<template
					v-if="!location_is_accessible"
				>
					<div class="booking__warning">
						<p class="booking__warning-heading">
							Location orientation required
						</p>
						<p>To book this tool, you’ll require an orientation to gain access to {{ location_name }}. This is bookable below or individually with a member of staff.</p>
					</div>
					<a
						href="/members/orientations"
						class="button button--large button--fullwidth button--invert button--center"
					>
						Book orientation
					</a>
				</template>
				<template
					v-else
				>
					<div
						v-if="specificInstanceMode"
						class="form__set"
					>
						<div class="form__checkbox form__checkbox--no-padding">
							<input
								id="booking-widget-specific-instance-requested"
								v-model="specific_instance_requested"
								type="checkbox"
								:disabled="!booking_location"
							>
							<label for="booking-widget-specific-instance-requested">
								I’d like a specific tool
							</label>
						</div>
					</div>
					<div
						v-if="specific_instance_active"
						class="form__set"
					>
						<label
							for="booking-widget-location"
							class="form__label"
						>
							Select a tool
						</label>
						<div class="form__icon form__icon--down">
							<select
								id="booking-widget-instance-id"
								v-model="booking_tool_instance"
								class="form__select"
								:disabled="!booking_location"
							>
								<option
									v-for="tool in tool_instances"
									:key="tool.id"
									:value="tool.id"
								>
									{{ tool.instance_id }}
								</option>
							</select>
						</div>
					</div>
					<div class="form__set form__set--less-margin">
						<label
							for="booking-widget-date"
							class="form__label"
						>
							Choose a date and time
							<span class="form__descriptor">
								Please note you can only book tools up to one month in advance.
							</span>
						</label>
						<DatePickerComponent
							input-id="booking-widget-date"
							:dates="booking_date"
							mode="single"
							:loading="loading_dates"
							:inline="false"
							:min-date="min_date_formatted"
							:max-date="max_date_formatted"
							:use-enabled-dates-mode="true"
							:enabled-dates="calendar_enabled_dates"
							@update="updateBookingDate"
							@update-month="updateCalendarEnabledDates"
						/>
					</div>
					<div class="form__set">
						<label
							for="booking-widget-timeslot"
							class="invisible"
						>
							Select a time
						</label>
						<div class="form__icon form__icon--down">
							<select
								id="booking-widget-timeslot"
								v-model="booking_datetime"
								class="form__select"
								:disabled="!timeslots_are_retrievable"
							>
								<option
									v-for="timeslot, index in timeslots"
									:key="index"
									:value="timeslot"
								>
									{{ getTimeslotRangeFromDate( timeslot ) }}
								</option>
							</select>
						</div>
					</div>
					<button
						class="button button--large button--fullwidth button--invert button--center"
						:disabled="!booking_can_be_confirmed"
						@click="setViewToConfirmBooking"
					>
						Book now
					</button>
				</template>
			</template>
		</template>
	</div>
</template>

<script>

import { storeToRefs } from 'pinia';

import { useUserStore } from '../shared/stores/user';

import moment from 'moment';

import DatePickerComponent from '../shared/components/DatePickerComponent.vue';

import gql_query_tools_by_location from './graphql/query/OperationalToolsByLocation.gql';
import gql_query_dates_with_bookable_tool_instances from '../shared/graphql/query/DatesWithBookableToolInstances.gql';
import gql_query_dates_when_tool_bookable from './graphql/query/DatesWhenToolBookable.gql';

const VIEW_INITIAL = 'initial';
const VIEW_CONFIRM_BOOKING = 'confirm_booking';
const VIEW_LOADING = 'loading';
const VIEW_SUCCESS = 'success';
const VIEW_ERROR = 'error';

export default {
	components: {
		DatePickerComponent,
	},
	props: {
		toolClassId: {
			required: true,
			type: String,
		},
		toolClassTitle: {
			required: true,
			type: String,
		},
		toolClassTimeslotLength: {
			required: true,
			type: Number,
		},
		locations: {
			required: true,
			type: Array,
		},
		specificInstanceMode: {
			required: false,
			type: Boolean,
			default: false,
		},
		accessibleLocationIds: {
			required: true,
			type: Array,
		},
	},
	setup() {
		const user_store = useUserStore();
		const { craft_user_data } = storeToRefs( user_store );
		return { craft_user_data };
	},
	data() {
		// Can't book earlier than today.
		const min_datepicker_date = moment();
		// Can't book later than one month into future.
		const max_datepicker_date = moment().add( 1, 'months' );

		return {
			booking_location: null,
			booking_tool_instance: null,
			booking_date: null, // Purely for UI purposes.
			booking_datetime: null, // This one gets sent to booking system.

			current_view: VIEW_INITIAL,

			specific_instance_requested: false,

			error_messages: [],

			tool_instances: [],
			calendar_enabled_dates: [],
			timeslots: [],

			min_date_formatted: min_datepicker_date.format( 'YYYY-MM-DD' ),
			max_date_formatted: max_datepicker_date.format( 'YYYY-MM-DD' ),

			agrees_to_agreement: false,

			loading_dates: false,

			// Hold copy of what datepicker is looking at, to control the
			// calendar enabled dates. I don't like copying the datepicker state
			// but it's a pain to get the datepicker to 're-month' itself based
			// on changes in this component otherwise.
			// Ideally the year/month view status of DatepickerComponent would
			// be props but it is not currently.
			booking_year: moment().format( 'YYYY' ),
			booking_month: moment().format( 'MM' ),
		};
	},
	computed: {
		location_is_set() {
			return this.booking_location !== null
				&& this.booking_location !== '';
		},
		location_is_accessible() {
			return this.location_is_set
				&& this.accessibleLocationIds.indexOf(
					this.booking_location
				) > -1;
		},
		booking_can_be_confirmed() {
			return this.location_is_set
				&& this.location_is_accessible
				&& this.booking_datetime !== null
				&& this.booking_datetime !== ''
				&& (
					!this.specific_instance_active
					|| (
						this.booking_tool_instance !== null
						&& this.booking_tool_instance !== ''
					)
				);
		},
		booking_can_be_made() {
			return this.booking_can_be_confirmed && this.agrees_to_agreement;
		},
		timeslots_are_retrievable() {
			return (
				this.location_is_set
				&& this.booking_date !== null
				&& this.booking_date !== ''
				&& (
					!this.specific_instance_active
					|| (
						this.booking_tool_instance !== null
						&& this.booking_tool_instance !== ''
					)
				)
			);
		},
		specific_instance_active() {
			return this.specificInstanceMode
				&& this.specific_instance_requested
			;
		},
		location_name() {
			if ( !this.booking_location ) {
				return '';
			}
			const location = this.locations.find(
				location => location.id.toString() === this.booking_location.toString()
			);
			if ( location ) {
				return location.title;
			}
			return '';
		},
	},
	watch: {
		booking_location( new_value ) {
			if ( new_value === null ) {
				return;
			}
			this.booking_tool_instance = null;
			this.booking_date = null;
			this.booking_datetime = null;
			if ( this.specific_instance_active ) {
				this.refreshToolInstances();
				this.refreshTimeslots();
			} else {
				this.refreshDaysWithBookableToolInstances();
				this.refreshTimeslots();
			}
		},
		specific_instance_requested( new_value ) {
			this.booking_datetime = null;
			if ( this.specificInstanceMode && new_value ) {
				this.refreshToolInstances();
				this.refreshTimeslots();
			} else {
				this.refreshDaysWithBookableToolInstances();
				this.refreshTimeslots();
			}
		},
		booking_tool_instance( new_value ) {
			if ( new_value === null ) {
				return;
			}
			this.booking_date = null;
			this.booking_datetime = null;
			this.refreshDaysWithBookableToolInstances();
			this.refreshTimeslots();
		},
		booking_date( new_value ) {
			if ( new_value === null ) {
				return;
			}
			this.refreshTimeslots();
		},
	},
	async mounted() {
		if ( this.locations.length === 1 ) {
			this.booking_location = this.locations[0].id;
		}
		const user_store = useUserStore();
		await user_store.assertSession();
	},
	methods: {
		async bookTool() {
			if ( !this.booking_can_be_made ) {
				return;
			}
			this.current_view = VIEW_LOADING;
			this.$craftActionApiClient.query(
				'iom/booking/save-member-tool-booking',
				this.specific_instance_active ? {
					tool_id: this.booking_tool_instance,
					timeslot_begins: this.booking_datetime,
				} : {
					tool_class_id: this.toolClassId,
					location_id: this.booking_location,
					timeslot_begins: this.booking_datetime,
				}
			).then( () => {
				this.current_view = VIEW_SUCCESS;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.current_view = VIEW_ERROR;
				if ( !error.response.data?.errors ) {
					// Shouldn't happen.
					return;
				}
				this.error_messages = Object.values( error.response.data.errors ).reduce(
					( item, acc ) => {
						item.forEach( message => {
							acc.push( message );
						} );
						return acc;
					},
					[]
				);
			} );
		},
		async refreshToolInstances() {
			this.tool_instances = [];
			if ( !this.specific_instance_active ) {
				return;
			}
			if ( !this.booking_location ) {
				return;
			}
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_tools_by_location,
				{
					location_id: parseInt( this.booking_location, 10 ),
					tool_class_id: parseInt( this.toolClassId, 10 ),
				}
			);
			this.tool_instances = response.data.entries;
		},
		async refreshDaysWithBookableToolInstances() {
			this.calendar_enabled_dates = [];
			this.loading_dates = true;
			if ( !this.booking_location ) {
				return;
			}
			if (
				this.specific_instance_active
				&& !this.booking_tool_instance
			) {
				return;
			}
			if ( this.specific_instance_active ) {
				const response = await this.$craftGraphqlApiClient.query(
					gql_query_dates_when_tool_bookable,
					{
						tool_id: parseInt( this.booking_tool_instance, 10 ),
						year: this.booking_year,
						month: this.booking_month,
					}
				);
				this.calendar_enabled_dates = response.data.datesWhenToolBookable;
			} else {
				const response = await this.$craftGraphqlApiClient.query(
					gql_query_dates_with_bookable_tool_instances,
					{
						tool_class_id: parseInt( this.toolClassId, 10 ),
						location_id: parseInt( this.booking_location, 10 ),
						year: this.booking_year,
						month: this.booking_month,
					}
				);
				this.calendar_enabled_dates = response.data.datesWithBookableToolInstances;
			}
			this.loading_dates = false;
		},
		async refreshTimeslots() {
			this.timeslots = [];
			if ( !this.timeslots_are_retrievable ) {
				return;
			}
			if ( this.specific_instance_active ) {
				this.timeslots = await this.$craftActionApiClient.query(
					'iom/booking/get-tool-timeslots',
					{
						tool_id: this.booking_tool_instance,
						date: this.booking_date,
					}
				);
			} else {
				this.timeslots = await this.$craftActionApiClient.query(
					'iom/booking/get-tool-class-timeslots',
					{
						tool_class_id: this.toolClassId,
						location_id: this.booking_location,
						date: this.booking_date,
					}
				);
			}
		},
		viewIsConfirmBooking() {
			return this.current_view === VIEW_CONFIRM_BOOKING;
		},
		viewIsSuccess() {
			return this.current_view === VIEW_SUCCESS;
		},
		viewIsLoading() {
			return this.current_view === VIEW_LOADING;
		},
		viewIsError() {
			return this.current_view === VIEW_ERROR;
		},
		setViewToConfirmBooking() {
			this.current_view = VIEW_CONFIRM_BOOKING;
		},
		resetErrorState() {
			this.error_messages = [];
			this.current_view = VIEW_INITIAL;
		},
		updateBookingDate( date ) {
			this.booking_date = date[0];
		},
		getToolInstanceIdFromId( tool_id ) {
			return this.tool_instances.find(
				tool => tool.id.toString() === tool_id.toString()
			).instance_id;
		},
		getTimeslotRangeFromDate( time ) {
			const start_time = moment( time );
			const end_time = moment( start_time ).add(
				this.toolClassTimeslotLength,
				'minutes'
			);
			return `${start_time.format( 'HH:mm' )} — ${end_time.format( 'HH:mm' )}`;
		},
		updateCalendarEnabledDates( year_month_data ) {
			this.booking_year = year_month_data.year.toString();
			this.booking_month = year_month_data.month.toString();
			this.refreshDaysWithBookableToolInstances();
		},
	},
};

</script>
